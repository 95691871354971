/*
Safari osx
mozilla/5.0 (macintosh; intel mac os x 10_15_2) applewebkit/605.1.15 (khtml, like gecko) version/13.0.4 safari/605.1.15

Safari iphone
mozilla/5.0 (iphone; cpu iphone os 13_3_1 like mac os x) applewebkit/605.1.15 (khtml, like gecko) version/13.0.5 mobile/15e148 safari/604.1

Safari ipad
mozilla/5.0 (ipad; cpu os 13_3_1 like mac os x) applewebkit/605.1.15 (khtml, like gecko) version/13.0.5 mobile/15e148 safari/604.1

Chrome osx
mozilla/5.0 (macintosh; intel mac os x 10_15_2) applewebkit/537.36 (khtml, like gecko) chrome/79.0.3945.130 safari/537.36

Chrome windows
mozilla/5.0 (windows nt 10.0; win64; x64) applewebkit/537.36 (khtml, like gecko) chrome/80.0.3987.122 safari/537.36

Chrome iphone
mozilla/5.0 (iphone; cpu iphone os 13_3 like mac os x) applewebkit/605.1.15 (khtml, like gecko) chrios/80.0.3987.95 mobile/15e148 safari/604.1

Chrome android
mozilla/5.0 (linux; andoird 9; sm-j530y) applewebkit/537.36 (khtml, like gecko) chrome/80.0.3987.117 mobile safari/537.36

Chrome ipad
mozilla/5.0 (ipad; cpu os 13_3 like mac os x) applewebkit/605.1.15 (khtml, like gecko) chrios/78.0.3904.84 mobile/15e148 safari/604.1

Edge windows
mozilla/5.0 (windows nt 10.0; win64; x64) applewebkit/537.36 (khtml, like gecko) chrome/80.0.3987.122 safari/537.36 edg/80.0.361.61

Firefox osx
mozilla/5.0 (macintosh; intel mac os x 10.15; rv:73.0) gecko/20100101 firefox/73.0

Firefox windows
mozilla/5.0 (windows nt 10.0; win64; x64; rv:73.0) gecko/20100101 firefox/73.0
*/

const UA = navigator.userAgent.toLowerCase();

if (test('iphone') && !test('chrios') && test('safari') ) {
	document.documentElement.classList.add('ios-safari');
}

function test(val) {
	if (UA.indexOf(val) == -1) {
		// console.log(`${val}:`, false);
		return false;
	}
	else {
		// console.log(`${val}:`, true);
		return true;
	} 
}
